<template>
  <footer :class="{
    'sm:px-7': true,
    '-z-10 -mt-[400px] px-5 pt-72': this.moveUp
  }">
    <!-- Contact  -->
    <div
      class="flex flex-col items-center justify-center space-y-1 py-20 text-center text-xl text-white sm:space-y-4 sm:text-3xl"
    >
      WieWaarWanneer is te bereiken via <br />
      <a
        href="mailto:contact@wiewaarwanneer.com"
        class="text-2xl text-blue-400 transition-all hover:scale-[101%] hover:text-pink-600 sm:text-4xl"
      >
        contact@wiewaarwanneer.com
      </a>
    </div>

    <!-- Separator -->
    <div class="mx-auto h-[1px] w-3/4 bg-gray-400 sm:w-[400px]"></div>

    <!-- Links -->
    <div
      class="mx-auto flex max-w-6xl justify-between pb-10 pt-20 text-[15px] font-light text-white px-2"
    >
      <ul class="flex flex-col justify-end space-y-3">
            <li v-for="item in footerLinks">
                <Link
                    :href="item.href"
                    class="px-1 transition-colors hover:text-blue-300"
                >
                    {{ item.name }}
                </Link>
            </li>
      </ul>
      <ul class="flex flex-col items-end space-y-2 xl:justify-end">
        <li>Made by <a href="https://mobieljoy.com" target="_blank">Mobieljoy</a></li>
        <li>KvK: 89380746</li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { Link } from "@inertiajs/vue3";

export default {
    props: {
        moveUp: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            footerLinks: [
                {
                    name: 'Ondersteuning',
                    href: '/support',
                },
                {
                    name: "Algemene voorwaarden",
                    href: "/termsofservice",
                },
                {
                    name: "Privacy Statement",
                    href: "/privacy",
                },
                {
                    name: "Cookies",
                    href: "/cookies",
                },
            ]
        }
    },
    components: {
        Link
    }
}
</script>

<style lang="postcss">
footer {
  @apply bg-blue-800 !bg-blend-overlay;
  background-image: url("/assets/images/constellation.svg");
}
</style>
