<template>
    <header
      class="fixed top-0 z-50 w-full bg-white bg-opacity-0 transition-all duration-300"
      :class="{
        'bg-opacity-100 shadow-md shadow-gray-500/10': windowY > 0,
      }"
    >
        <nav aria-label="Global">
            <div
                class="flex items-center justify-between px-4 py-0 transition-all sm:p-5 lg:px-7"
                :class="{
                    'sm:py-3': windowY > 100,
                }"
            >
                <div class="sm:flex lg:flex-1">
                    <Link href="/" class="my-2 flex h-10 rounded-md p-1.5 sm:my-0">
                        <span class="sr-only">Wie Waar Wanneer</span>
                        <img class="h-6 w-auto sm:h-7 md:h-8" :src="asset('assets/images/logo-text.png')" alt="" />
                    </Link>
                </div>

                <div class="hidden lg:flex lg:gap-x-12" v-if="this.displayItems">
                    <a
                        v-for="item in navigation"
                        :key="item.name"
                        :href="item.href"
                        class="navItem rounded-md px-1 font-semibold leading-6 text-gray-800 transition-all duration-100 hover:text-black active:text-blue-600"
                    >
                        {{ item.name }}
                    </a>
                </div>
                <div class="flex flex-1 justify-end space-x-3">
                    <ButtonCustom
                        :href="'/login'"
                        class="hidden border border-blue-600 !py-1 !text-sm !text-blue-600 hover:border-blue-500 hover:bg-white/50 hover:!text-blue-500 sm:flex"
                        v-if="!this.auth.isAuthenticated"
                    >
                        Login <ArrowRightIcon class="ml-1.5 h-4" aria-hidden="true" />
                    </ButtonCustom>

                    <ButtonCustom
                        :href="'/register'"
                        class="hidden bg-blue-600 !py-1 !text-sm text-white hover:bg-blue-500 sm:flex"
                        v-if="!this.auth.isAuthenticated"
                    >
                        Registeer
                    </ButtonCustom>

                    <ButtonCustom
                        :href="'/dashboard'"
                        class="hidden border border-blue-600 !py-1 !text-sm !text-blue-600 hover:border-blue-500 hover:bg-white/50 hover:!text-blue-500 sm:flex"
                        v-if="this.auth.isAuthenticated"
                    >
                        Dashboard
                    </ButtonCustom>

                    <div class="flex pl-5 lg:hidden">
                        <button
                            role="button"
                            class="-m-2.5 inline-flex items-center justify-center rounded-md px-2.5 py-1 text-gray-700"
                            @click="this.mobileMenuOpen = true"
                            aria-label="Open main menu"
                        >
                            <span class="sr-only">Open main menu</span>
                            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </nav>

        <!-- Mobile menu -->
        <TransitionRoot class="lg:hidden" as="template" :show="this.mobileMenuOpen">
            <Dialog as="div" class="relative z-[150]" @close="open = false">
                <TransitionChild
                    as="template"
                    enter="ease-in-out duration-500"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in-out duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div
                        @click="this.mobileMenuOpen = false"
                        class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
                    />
                </TransitionChild>

                <div class="fixed inset-0 overflow-hidden">
                    <div class="absolute inset-0 overflow-hidden">
                        <div
                            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
                        >
                            <TransitionChild
                                as="template"
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enter-from="translate-x-full"
                                enter-to="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leave-from="translate-x-0"
                                leave-to="translate-x-full"
                            >
                                <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                    <div
                                        class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
                                    >
                                        <div class="px-4 sm:px-6">
                                            <div class="flex items-start justify-between">
                                                <img class="h-7 w-auto" :src="asset('assets/images/logo-text.png')" alt="" />
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button
                                                        role="button"
                                                        class="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                                                        @click="this.mobileMenuOpen = false"
                                                        aria-label="Close panel"
                                                    >
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Close panel</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="relative mt-6 flex-1">
                                            <!-- Your content -->

                                            <div
                                                @click="this.mobileMenuOpen = false"
                                                class="flex flex-col pb-8"
                                                v-if="this.displayItems"
                                            >
                                                <a
                                                    v-for="item in navigation"
                                                    :key="item.name"
                                                    :href="item.href"
                                                    class="px-8 py-5 font-semibold text-gray-800 hover:bg-zinc-100"
                                                >
                                                    {{ item.name }}
                                                </a>
                                            </div>

                                            <div
                                                @click="this.mobileMenuOpen = false"
                                                class="absolute bottom-0 mt-5 flex w-full flex-col items-center justify-center space-y-5 py-5 sm:flex-row sm:space-x-4 sm:space-y-0"
                                            >
                                                <ButtonCustom
                                                    :href="'/login'"
                                                    class="!w-9/10 flex border border-blue-600 !text-sm !text-blue-600 hover:border-blue-500 hover:bg-white/50 hover:!text-blue-500"
                                                    v-if="!this.auth.isAuthenticated"
                                                >
                                                    Login
                                                    <ArrowRightIcon
                                                        class="ml-1.5 h-4"
                                                        aria-hidden="true"
                                                    />
                                                </ButtonCustom>

                                                <ButtonCustom
                                                    :href="'/register'"
                                                    class="!w-9/10 flex bg-blue-600 !text-sm text-white hover:bg-blue-500"
                                                    v-if="!this.auth.isAuthenticated"
                                                >
                                                    Registeer
                                                </ButtonCustom>

                                                <ButtonCustom
                                                    :href="'/dashboard'"
                                                    class="!w-9/10 flex border border-blue-600 !text-sm !text-blue-600 hover:border-blue-500 hover:bg-white/50 hover:!text-blue-500"
                                                    v-if="this.auth.isAuthenticated"
                                                >
                                                    Dashboard
                                                </ButtonCustom>
                                            </div>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
        <button
            role="button"
            @click="this.scrollToTop()"
            class="group fixed -right-14 bottom-2 z-[100] flex h-14 w-14 cursor-pointer items-center justify-center transition-all duration-500"
            :class="{ '-translate-x-[120%]': this.windowY > 500 }"
            v-if="!this.hideScrollUp"
        >
            <ArrowUpIcon
                class="h-11 rounded-full border border-gray-100 bg-white p-1.5 text-blue-600 shadow-md transition-all group-hover:bg-blue-400 group-hover:text-white md:h-10"
            />
        </button>
    </header>
</template>

<script>
import { ref } from 'vue';
import { ArrowRightIcon, ArrowUpIcon, Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import ButtonCustom from "@/Components/Button.vue";
import { Link, usePage } from "@inertiajs/vue3";

export default {
    props: {
        hideScrollUp: {
            type: Boolean,
            default: false
        },
        displayItems: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            windowY: 0,
            mobileMenuOpen: false,
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    },
    mounted() {
        this.windowY = ref(window.scrollY);
        window.addEventListener("scroll", () => {
            this.windowY = window.scrollY
        });
    },
    beforeUnmount() {
        window.removeEventListener("scroll", () => {
            this.windowY = window.scrollY
        });
    },
    setup() {
        const navigation = [
            { name: "Functionaliteit", href: "#features" },
            { name: "Prijzen", href: "#pricing" },
        ];
        const auth = usePage().props.auth;
        return { navigation, auth };
    },
    components: {
        ArrowRightIcon,
        ArrowUpIcon,
        Bars3Icon,
        XMarkIcon,
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        ButtonCustom,
        Link
    }
}
</script>

<style lang="postcss">
.navButton {
  @apply rounded-full border border-blue-600 px-4 py-1 text-sm font-semibold transition-all duration-300;
}

.mobileNav a {
  @apply block rounded-lg px-4 py-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 sm:px-3 sm:py-2;
}

.slide-enter-active,
.slide-leave-active {
  transition: width 1s ease-out;
}
.slide-enter,
.slide-leave-to {
  width: 0;
}

@keyframes slideIn {
  0% {
    max-width: 0%;
  }
  50% {
    max-width: 50%;
  }
  100% {
    max-width: 100%;
  }
}
</style>
